@import 'theme/variable';
$primary: #bd0e7d;
$secondary: #d52696;
$dark: #d52696;
$light:#fd81ab;
@import 'theme/style';
@import 'theme/responsive';
@import 'theme/customize';
@import 'theme/theme-dark';
@import 'theme/theme-rtl';
